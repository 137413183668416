<template>
  <div class="order-info">
    <div class="earned-coins-label" v-if="coins">
      <div class="m-icon">
        <Icon name="medusa-icon" />
      </div>
      <p>+{{ coins }}</p>
      <span>Medusa Coins Earned </span>
    </div>
    <dl class="info-list">
      <!-- <div>
          <p>asdasdasd</p>
          <p>a</p>
          <p>98798789</p>
      </div> -->
      <table>
        <tbody>
          <tr>
            <td colspan="2">Order Total</td>
            <td class="text-right w-40">
              ${{ commaFormat(Number(summary.data.subTotal).toFixed(2)) }}
            </td>
          </tr>
          <tr v-if="summary.data.taxTotal">
            <td colspan="2">Taxes</td>
            <td class="text-right">
              ${{ currencyFormat(summary.data.taxTotal) }}
            </td>
          </tr>
          <tr>
            <td colspan="2">Shipping</td>
            <td class="text-right">
              ${{ commaFormat(Number(summary.data.shipping).toFixed(2)) }}
            </td>
          </tr>
          <template
            v-if="summary.data.discount ? summary.data.discount.length > 0 : ''"
          >
            <template v-for="d in summary.data.discount" :key="d.id">
              <tr>
                <td colspan="2">{{ d.label }}</td>
                <td class="text-right">
                  -${{
                    commaFormat(
                      Number(d.price.toString().replace("-", "")).toFixed(2)
                    )
                  }}
                </td>
              </tr>
            </template>
          </template>
          <!-- <tr>
                  <td colspan="2">Sub Total</td>
                    <td class="text-right">${{ commaFormat(summary.data.subTotal) }}</td>
                </tr> -->
          <tr v-if="store">
            <td colspan="2">
              <div class="flex items-center space-x-2">
                <span>{{ store.label }}</span>
                <Icon
                  name="delete"
                  class="w-4 h-4 cursor-pointer -mt-1"
                  @click="$emit('removeStoreCredit')"
                />
              </div>
            </td>
            <td class="text-right">-${{ currencyFormat(store.value) }}</td>
          </tr>
          <tr v-if="summary.appliedCoins != null">
            <td colspan="2">
              <div class="flex items-center space-x-2">
                <span>Coins Discount</span>
                <Icon
                  name="delete"
                  class="w-4 h-4 cursor-pointer -mt-1"
                  @click="$emit('removePoint')"
                />
              </div>
            </td>
            <td class="text-right">
              -${{
                commaFormat(Number(summary.appliedCoins.discount).toFixed(2))
              }}
            </td>
          </tr>
          <template v-for="coupon in summary.data.coupons" :key="coupon">
            <tr>
              <td colspan="2">
                <div class="flex items-center space-x-2">
                  <span>{{ coupon.label }}</span>
                  <Icon
                    name="delete"
                    class="w-4 h-4 cursor-pointer -mt-1"
                    @click="$emit('removeCoupon', coupon.id)"
                  />
                </div>
              </td>
              <td class="text-right">
                -${{ currencyFormat(coupon.cartDiscount) }}
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="2">Total</td>
            <td class="text-right">
              ${{
                commaFormat(
                  Number(summary.data.gTotal - getDiscount()).toFixed(2)
                )
              }}
              <!-- ${{
                summary.appliedCoins != null
                  ? commaFormat(
                      (
                        parseFloat(summary.data.gTotal) -
                        parseFloat(summary.appliedCoins.discount)
                      ).toFixed(2)
                    )
                  : commaFormat(Number(summary.data.gTotal - getStoreCredit(store)).toFixed(2))
              }} -->
            </td>
          </tr>
        </tfoot>
      </table>
      <!-- <div class="info-list-item">
        <dt>Order Total</dt>
        <dd>${{ commaFormat(Number(summary.data.subTotal).toFixed(2)) }}</dd>
      </div>

      <div v-if="summary.data.taxTotal" class="info-list-item">
        <dt>Taxes</dt>
        <dd>${{ currencyFormat(summary.data.taxTotal) }}</dd>
      </div>

      <div class="info-list-item">
        <dt>Shipping</dt>
        <dd>${{ commaFormat(Number(summary.data.shipping).toFixed(2)) }}</dd>
      </div>
      <div v-if="summary.data.discount ? summary.data.discount.length > 0 : ''" class="info-list-item" >
        <template v-for="d in summary.data.discount" :key="d.id">
          <dt>{{ d.label }}</dt>
          <dd> -${{ commaFormat(Number(d.price.replace("-", "")).toFixed(2)) }} </dd>
        </template>
      </div> -->

      <!-- <div class="info-list-item highlight">
        <dt>Sub Total</dt>
        <dd>${{ commaFormat(summary.data.subTotal) }}</dd>
      </div> -->

      <!-- <div class="info-list-item" v-if="summary.appliedCoins != null">
        <dt class="flex items-center space-x-2">
          <span>Coins Discount</span>
          <Icon
            name="delete"
            class="w-4 h-4 cursor-pointer -mt-1"
            @click="$emit('removePoint')"
          />
        </dt>
        <dd class="flex flex-row-reverse items-center">
          -${{ commaFormat(summary.appliedCoins.discount) }}
        </dd>
      </div>

      <div
        class="info-list-item"
        v-for="coupon in summary.data.coupons"
        :key="coupon"
      >
        <dt>{{ coupon.label }}</dt>
        <dd class="flex flex-row-reverse items-center">
          -${{
            summary.data.coupons[0]
              ? currencyFormat(summary.data.coupons[0].cartDiscount)
              : null
          }}
          <Icon
            name="delete"
            class="w-5 h-5 cursor-pointer"
            @click="$emit('removeCoupon', coupon.id)"
          />
        </dd>
      </div> -->

      <!-- <div class="info-list-item highlight">
        <dt>Total</dt>
        <dd> ${{ summary.appliedCoins != null ? commaFormat( ( parseFloat(summary.data.gTotal) - parseFloat(summary.appliedCoins.discount) ).toFixed(2) ) : commaFormat(Number(summary.data.gTotal).toFixed(2)) }} </dd>
      </div> -->
    </dl>
  </div>
</template>

<script>
import Mymixin from "@/modules/mixins";

export default {
  name: "OrderSummary",
  mixins: [Mymixin],
  props: {
    summary: {
      type: Object,
    },
    store: {
      type: Object,
    },
    coins: {
      type: [Number, String],
    },
  },
  methods: {
    getDiscount() {
      let store = this.store ? parseFloat(this.store.value) : 0;
      let coins = this.summary.appliedCoins
        ? parseFloat(this.summary.appliedCoins.discount)
        : 0;
      return store + coins;
    },
  },
};
</script>

<style lang="scss" scoped>
.order-info {
  @apply flex flex-col justify-between w-full xl:max-w-lg flex-shrink-0 xl:border-r border-theme-border border-opacity-50;

  .info-list {
    @apply flex flex-col text-base space-y-2 pt-4 px-4;

    .info-list-item {
      @apply flex font-light text-theme-body items-center justify-between;

      &.highlight {
        @apply font-semibold text-black py-2.5 border-t border-theme-border border-opacity-50;
      }
    }
  }

  &.on-summary {
    @apply border-0 max-w-full w-full pt-0;
    margin-top: -2px;

    &.has-padding {
      .info-list {
        @apply pt-2;
      }
    }

    .info-list {
      @apply px-0 bg-white pt-0;

      .info-list-item {
        @apply px-4;
      }
      td {
        @apply text-md;
      }
    }
  }

  .earned-coins-label {
    @apply w-full flex items-center bg-theme-primary bg-opacity-10 py-4 px-4 space-x-3 text-sm mb-6;

    .m-icon {
      @apply w-5 h-5 rounded-full bg-theme-primary flex items-center justify-center;
      svg {
        @apply w-3 h-3 text-white;
      }
    }

    p {
      color: #42372c;
    }
    span {
      color: rgba(0, 0, 0, 0.53);
    }
  }
}
table {
  @apply min-w-full divide-y divide-gray-200;
  width: 100%;
  word-break: break-word;

  tbody {
    td {
      @apply px-4 py-1 text-gray-400 font-light;
    }
  }

  tfoot {
    td {
      @apply px-4 py-3 font-medium;
    }
  }
}
</style>
